import $http from '../../../utils/http.js';

// 工具管理
/**
 * 工具管理列表
 * @param data
 */
export function getToolsManageList(data) {
    // /PackageOrder/index
    return $http.get('/Tool/tool_index', data, 'loadingDiv');
}

/**
 * 工具管理列表停用/启用操作
 * @param data
 */
export function toolsManageChangeStatus(data) {
    return $http.post('/Tool/t_enable', data, 'loadingDiv');
}

/**
 * 工具管理列表编辑操作
 * @param data
 */
export function toolsManageEdit(data) {
    return $http.post('/Tool/t_edit', data, 'loadingDiv');
}


// 类型管理
/**
 * 类型管理列表
 * @param data
 */
export function getTypeManageList(data) {
    // /PackageOrder/index
    return $http.get('/Tool/tool_type_index', data, 'loadingDiv');
}

/**
 * 类型管理列表停用/启用操作
 * @param data
 */
export function typeManageChangeStatus(data) {
    return $http.post('/Tool/tool_type_enable', data, 'loadingDiv');
}

/**
 * 工具管理列表编辑操作
 * @param data
 */
export function typeManageEdit(data) {
    return $http.post('/Tool/tool_type_edit', data, 'loadingDiv');
}