<template>
    <div>
        <el-row style="padding: 25px">
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="名称:">
                        <el-input style="width: 200px" v-model="searchForm.searchVal" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select clearable style="width: 200px" v-model="searchForm.status">
                            <el-option v-for="item in statusArray" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="typeManageDataList" border
                    align="center" style="width: 100%">
                    <el-table-column width="90px" label="序号" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="typeNumber" label="编号" align="center"></el-table-column>
                    <el-table-column prop="typeName" label="名称" align="center"></el-table-column>
                    <!-- <el-table-column prop="tType" label="类型" align="center"></el-table-column> -->
                    <el-table-column prop="typeIsEnable" label="状态" align="center">
                        <template v-slot="scope">
                            <div>{{ statusName(scope.row) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeTotal" label="数据" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button @click="handleEditBtn(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="handleChangeStatus(scope.row)" type="text" size="small">{{
                            scope.row.typeIsEnable === 0 ? '启用' : '停用'
                            }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <!-- 编辑弹窗 -->
        <dialogFrame :title="'类型编辑'" :show="dialogFormVisible" @showDialog="showDialog" :footer="false" width="450px"
            center>
            <typeEdit :typeEditData="typeEditData" @close="closeDialog"></typeEdit>
        </dialogFrame>
    </div>
</template>

<script>
import { statusList } from '@/common/js/common-data/status-list.js';
import jsonTool from '@/utils/jsonTool.js';
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import typeEdit from '../component/tools-manage/typeManageEdit.vue';
import { getTypeManageList, typeManageChangeStatus } from '@/common/api/tools-manage/toolsManage.js';
export default {
    components: {
        dialogFrame,
        typeEdit
    },
    data() {
        return {
            searchForm: {
                searchVal: '',
                status: ''
            },
            // 状态列表
            statusArray: statusList,
            typeManageDataList: [],
            // 权限操作集合
            permissionsAction: {},
            page: {
                page: 1,
                pageSize: 10,
                total: 0
            },
            // 是否显示弹窗
            dialogFormVisible: false,
            // 编辑回显数据
            typeEditData: {}
        };
    },
    mounted() {
        this.queryTypeManageData();
    },
    methods: {
        showDialog(val) {
            this.dialogFormVisible = val;
        },
        closeDialog(val) {
            this.dialogFormVisible = val;
            this.queryTypeManageData();
        },
        // 状态名
        statusName(row) {
            return this.statusArray.find(val => val.id === row.typeIsEnable)?.name;
        },
        queryTypeManageData() {
            let params = {
                page: this.page.page,
                pageSize: this.page.pageSize,
                search: this.searchForm.searchVal,
                type_is_enable: this.searchForm.status
            };
            getTypeManageList(params)
                .then(res => {
                    let { code, result, auth } = res;
                    if (code == 200) {
                        this.typeManageDataList = jsonTool(result);
                        // let { Edit: edit, Enable: enable } = Power;
                        // this.permissionsAction = {
                        //     edit,
                        //     enable
                        // };
                        // console.log('this.permissionsAction: ', this.permissionsAction);
                        this.page.total = res.count;
                        // console.log('this.typeManageDataList: ', this.typeManageDataList);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查询按钮
        handleSearch() {
            this.queryTypeManageData();
        },
        // 处理编辑按钮
        handleEditBtn(row) {
            this.dialogFormVisible = true;
            let { typeId, typeName } = row;
            this.typeEditData = {
                typeId,
                typeName
            };
        },
        // 处理停用/启用按钮
        handleChangeStatus(row) {
            let actionName = row.typeIsEnable === 0 ? '启用' : '停用';
            let status = row.typeIsEnable == 0 ? 1 : 0;
            let params = {
                type_id: row.typeId,
                type_is_enable: status
            };
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    typeManageChangeStatus(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!',
                                    center: true
                                });
                                this.queryTypeManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryTypeManageData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.queryTypeManageData();
        }
    }
};
</script>

<style lang="scss" scoped>

</style>